module.exports = {
  language: {
    name: '中文',
    title: '语言',
  },

  about: {
    title: '全球对讲调度台',
    conpanyname: '深圳市博纳德信息技术有限公司'
  },
  login: {
    account: '输入登录账号',
    password: '输入密码',
    installationServices: '安装语音服务',
    rememberme: '记住账号',
    Login: '登录'
  },
  message: {
    loginAccountTips: '登录账号不能为空',
    loginPasswordTips: '密码不能为空',
    usernamePasswordTips: '账号或密码错误',
    loginsuccessful: '登录成功',
    accountNumbers: '账号只能输入数字!',
    noDispatcher: '登录失败,该账号不是调度员!',
    accountNotpresent: '账号不存在!',
    LoginAbnormal: '登录异常,请重试!',
    voiceServiceNotInstalled: '请安装语音服务，若安装请刷新浏览器！',
    Videocallinprogress: '正在视频呼叫！',
    Voicecallinprogress: '正在语音通话！',
    Youhavebeenclosedforspeaking: '您已被关闭发言！',
    tctemporarygroup: '确认刷新并退出临时群组吗？',
    Doyouwanttologout: '是否退出登录?',
    prompt: '提示',
    Nonetworklink: '无网络链接',
    deletedGro: '此群组已被删除，无法操作！',
    Pleaseselectthedesired: '请选择需要',
    deletedGroUsers: '的用户！',
    Voicecallfunction: '请先在管理平台开启语音通话功能！',
    Alreadytemporary: '已在临时群组，无法呼叫!',
    Onlysupports: '只支持对单个用户发起语音通话!',
    Enablevoicecalls: '此用户未在管理平台开启语音通话功能！',
    Oncall: '正在通话中，无法创建！',
    Shaketodeath: '摇毙中，不可创建',
    Shaketodeath2: '摇毙中，不可切换群组',
    createregiongroups: '最多只能创建3个区域群组!',
    temporarygroupsnoadd: '已在临时群组，无法创建！',
    haleDispatcherGroupUser: '只能强拉调度员所在群组的成员',
    TemporaryGroupNoHale: '临时群组,不可强拉！',
    Groupcallfailure: '组呼失败',
    Exitsuccessfully: '退出成功!',
    Usernotonline: '用户不在线!',
    Theotherpartyisbusy: '对方忙线中！',
    Callfailed: '呼叫失败！',
    Theotherpartyhashungup: '对方已挂断！',
    Networkconnectionfailed: '网络连接失败,清稍后重试',
    Callended: '通话结束！',
    Accountpassworderror: '账号密码错误',
    Arrears: '欠费',
    Accountdoesnotexist: '帐号不存在',
    Permissionerror: '权限错误',
    AccountInfoChange: '账号信息已变更',
    Configuration: '配置信息错误',
    accountOtherlogins: '账号已在其他地方登录！',
    Accountlogintimeout: '帐号登录超时',
    Networkconnectionfailure: '⽹络连接失败',
    Thenetworkisreconnecting: '⽹络正在重连',
    Addinggroupfailed: '加⼊群组失败',
    Addgrouprequesttimeout: '加⼊群组请求超时',
    Singlecallfailure: '单呼失败',
    Failedtojointhegroup: '加入群组失败[权限错误]',
    Failedtojointhegroup2: '加入群组失败[不存在]',
    Failedtojointhegroup3: '加入群组失败[过期]',
    Failedtojointhegroup4: '加入群组失败[配额限制]',
    Failedtojointhegroup5: '加入群组失败[有效的对象为空]',
    noSnatchingwheat: '短暂时间内多次抢麦,不允许',
    noRemoteclosureSnatchingwheat: '遥闭状态抢麦,不允许',
    Failedtograbthewheat: '抢麦时申请音频焦点失败',
    Grabbingwheattimeout: '接收抢麦回应超时',
    Failedtoopenrecordingdevice: '打开录音设备失败',
    Accountlocked: '账号被锁定',
    Terminallocked: '终端被锁定',
    Machinecardlocked: '机卡被锁定',
    Noaccountinformationconfigured: '没有配置帐号信息',
    Successfullysaved: '保存成功!',
    Successfullysethost: '设置主持人成功!',
    Successfullydeleted: '删除成功!',
    Successfullyadded: '添加成功！',
    Successfullyremoved: '移除成功！',
    update_successfully: '更新成功',
    Successfullymodified: '修改成功！',
    oldpassworderror: '旧密码错误',
    Parametererror: '参数错误',
    Pleaseselecttheuseryouwanttoadd: '请选择需要添加的用户！',
    Addingfailed: '添加失败，不在组用户列表为空！',
    Pleaseselectuserremoved: '请选择需要移除的用户！',
    Removalfailed: '移除失败，在组用户列表为空！',
    Prioritysetsuccessfully: '优先级设置成功',
    Pleaseselectastartandendtime: '请选择开始和结束时间',
    endTimeNoStarttime: '结束时间不能早于开始时间',
    Thestartandendtimesmustbeonthesameday: '开始时间和结束时间必须为同一天',
    RedefineCustomization: '自定义区域点数少于3个,请重新划!',
    Unabletocreatetemporarygroup: '暂无在线成员，无法建立临时群组！',
    Pleaseenteroldpassword: '请输入旧密码',
    Pleaseenteranewpassword: '请输入新密码',
    Confirmnewpassword: '确认输入新密码',
    noSamePassword: '旧密码与新密码不能一致！',
    noSamePasswordTow: '两次输入密码不一致!',
    pleaseenterpasswordagain: '请再次输入密码',
    Successfullyprocessed: '处理成功'
  },
  search: '搜索',
  users: '用户',
  refund: '返回',
  Loadingdesperately: '拼命加载中',
  Speaking: '正在讲话...',
  Iamspeaking: '我正在讲话...',
  communicatebytelephone: '通话',
  call: '呼叫',
  hale: '强拉',
  Forceddemolition: '强拆',
  confirm: '确定',
  cancel: '取消',
  dispatchIntercom: '调度对讲',
  allGroups: '全部群组',
  fixedGroup: '固定群组',
  regionalGroup: '区域群组',
  filter: '筛选',
  all: '全部',
  online: '在线',
  offline: '离线',
  groupUser: '群组/用户',
  enter: '进入',
  edit: '编辑',
  voiceIntercom: '语音对讲',
  baseStationSwitch: '基站开关',
  gpsSwitch: 'GPS开关',
  viewHistoricalTrack: '查看历史轨迹',
  startingtime1: '起始时间',
  selectionPeriod: '选择时间',
  startingTime: '开始时间',
  Pleaseselectadateandtime: '请选择开始时间',
  EndTime: '结束时间',
  Pleaseselectanendtime: '请选择结束时间',
  selectDate: '选择日期',
  SelectStartTime: '选择开始时间',
  Now: '此刻',
  Sure: '确定',
  Closure: '关闭',
  editArea: '修改区域',
  deleteArea: '删除区域',
  Areyousuretosavethefence: '确定保存围栏吗？',
  deleteLocalgroup: '确定要删除区域群组吗？',
  pleaseselectaregion: '请先圈选围栏区域！',
  doubleclicktocreate: '双击创建',
  doubleclick: '双击',
  Createregionalgroups: '创建区域群组',
  groupName: '群组名称',
  Pleaseenterthegroupname: '请输入群组名称',
  pleaseenteragroupname: '请输入群组名称（由2-15位数字,中文,英文组成）',
  groupnameTips: '群组名称由2-15位数字,中文,英文组成',
  pleaseenteragroupnameTips: '由2-15位数字,中文,英文组成',
  selectGroup: '选择群组',
  Pinnedingroupusers: '固定在组用户',
  TemporaryGroup: '临时在组用户',
  Usernotingroup: '不在组用户',
  userImei: '用户/IMEI',
  Pleaseselectauser: '请选择用户',
  Create: '创建',
  Cancel: '取消',
  groupEdit: '群组编辑',
  usersingroup: '在组用户',
  UsernameImei: '用户名称/IMEI',
  userName: '用户名称',
  noData: '暂无数据',
  removeselectedusers: '移除选中的用户',
  Removeall: '全部移除',
  Addtheselecteduser: '添加选中的用户',
  Addall: '全部添加',
  Save: '保存',
  selectUser: '选择用户',
  Edittakeseffect: '编辑即生效',
  UserManagement: '用户管理',
  Userpriority: '用户优先级',
  levelone: '一级',
  Secondary: '二级',
  Levelthree: '三级',
  Levelfour: '四级',
  Fifthgrade: '五级',
  Editstakeeffectimmediately: '编辑立即生效',
  Setmoderator: '设置主持人',
  SelectHost: '选择主持人',
  Host: '主持人',
  Pleasechoose: '请选择',
  Deletemoderator: '删除主持人',
  Setuphost: '只能设置一个用户为主持人',
  Pleaseselectacall: '请选择需要通话的内容',
  Waitingforanswering: '等待对方接听语音',
  Inviteyoutovoicecall: '邀请你语音通话',
  callingusers: '请选择需要呼叫的用户',
  Forcedpullusers: '请选择需要强拉的用户',
  Forceddismantlingusers: '请选择需要强拆的用户',
  SearchLocation: '搜地点',
  Show: '显示',
  Hide: '隐藏',
  Default: '默认',
  Satellite: '卫星',
  Roadconditions: '路况',
  Toolbox: '工具箱',
  Ranging: '测距',
  Mark: '标记',
  Broadcast: '广播',
  EnableBroadcastGroup: '请先开启接收广播的群组',
  textBroadcast: '文字广播',
  Hasbeensent: '已发送',
  textBroadcastContent: '请输入文字广播内容（不超过50字）',
  pleaseentertextbroadcast: '请输入文字广播',
  Send: '发送',
  voiceBroadcast: '语音广播',
  Clicktorecordvoicebroadcast: '点击录制语音广播',
  Pleaserecordthevoicebroadcast: '请录制语音广播',
  RecordingTime: '录音时间不能少于1秒',
  Broadcaster: '广播人',
  BroadcastTime: '广播时间',
  listento: '收听',
  Warningmessage: '告警信息',
  AlarmHistory: '更多告警消息请查看历史记录',
  Name: '名称',
  Address: '地址',
  Time: '时间',
  Check: '查看',
  State: '状态',
  Unprocessed: '未处理',
  DealWith: '处理',
  delete: '删除',
  PTTbuttondisplay: 'PTT按键显示',
  SOSalarmsound: 'SOS告警声音',
  Fencealarmsound: '围栏告警声音',
  Motiontrajectorybinding: '运动轨迹绑路',
  Changepassword: '修改密码',
  Logoutoflogin: '退出登录',
  Dataanalysis: '数据分析',
  Statisticstoday: '今日统计',
  Currentonlineusers: '当下在线用户数',
  Totalnumberofusers: '用户总数',
  Fencerecord: '围栏记录',
  SOSrecord: 'SOS记录',
  recordingrecord: '录音记录',
  Grouponlineusers: '群组在线用户',
  Comparison: '7天在线用户数对比',
  warningcomparison: '30天预警对比',
  Fencewarning: '围栏预警',
  SOSwarning: 'SOS预警',
  OldPassword: '旧密码',
  NewPassword: '新密码',
  ConfirmPassword: '确认密码',
  consists: '由6-16位字母，数字组成',
  startingpoint: '起点',
  totallength: '总长',
  ico: '图标',
  date: '日期',
  Targeting: '定位方式',
  Basestationpositioning: '基站定位',
  BeidouGPS: '北斗/GPS',
  direction: '方向',
  altitude: '海拔',
  Speed: '速度(KM/H)',
  warmreminder: '温馨提示：数据来自第三方，会存在误差，仅供参考。',
  Iconselection: '图标选择',
  pleaseentername: '请输入名称',
  changeicon: '更换图标',
  Universal: '通用',
  policesecurity: '警保',
  Firefighting: '消防',
  Chemicalindustry: '化工',
  other: '其他',
  save: '保存',
  close: '关闭',
  viewtag: '查看标记',
  addtag: '添加标记',
  Supportsaddingamaximum: '最大支持添加10个图标',
  Imageformaterror: '图片格式错误',
  uploadedimage: '上传图片大小不能超过5kb!',
  uploadsuccessful: '上传成功',
  uploadfailed: '上传失败',
  uploadfailedimage: '上传失败：图片最大支持像素25×25！',
  Successfuloperation: '操作成功',
  operationfailed: '操作失败',
  Editmark: '编辑标记',
  Pleaseselecttheiconyouwanttodelete: '请选择需要删除的图标',
  successfullydeleted: '删除成功',
  failedtodelete: '删除失败',
  north: '北',
  South: '南',
  West: '西',
  East: '东',
  truenorth: '正北',
  duesouth: '正南',
  duewest: '正西',
  Zhengdong: '正东',
  partial: '偏',
  historyrecord: '历史记录',
  group: '群组',
  pleaseselectagroup: '请选择群组',
  sender: '发送人',
  Pleaseselectuser: '请选择用户',
  Sendtime: '发送时间',
  startrange: '开始范围',
  endrange: '结束范围',
  startdate: '开始日期',
  Startingtime: '开始时间',
  enddate: '结束日期',
  to: '至',
  Clear: '清空',
  Confirm: '确定',
  state: '状态',
  Pleaseselectastatus: '请选择状态',
  Processed: '已处理',
  Processor: '处理人',
  processtime: '处理时间',
  serialnumber: '序号',
  operate: '操作',
  ViewSOSlocation: '查看SOS位置',
  Back: '返回',
  Warmreminder: '温馨提示：数据来源第三方，会存在误差，仅供参考',
  SOSalarmsintemporarygroupsarenotrecorded: '临时群组中的SOS告警不做记录',
  Pleaseselectatype: '请选择类型',
  deleted: '已删除',
  next: '下一条',
  Tobeprocessed: '待处理',
  dealwith: '处理',
  alreadydispatched: '已被调度员',
  temporarygroup: '临时群组',
  NortheasternHemisphere: '东北半球',
  northwesternhemisphere: '西北半球',
  SoutheasternHemisphere: '东南半球',
  southwesternhemisphere: '西南半球',
  Nolocationtrack: '没有位置轨迹',
  Latitudeandlongitude: '经纬度',
  Reportingtime: '上报时间',
  Positioningtime: '定位时间',
  Alarminformation: '告警信息',
  AlarminformationTips: '更多告警消息请查看历史记录',
  initiatesos: '发起sos',
  Forcedvideo: '强拉视频',
  videocall: '视频呼叫',
  Videopostback: '视频回传',
  leave: '离开',
  Enter: '进入',
  fence: '围栏',
  newgroup: '新建群组',
  Joingroup: '加入群组',
  Thegroupnamealreadyexistspleasereenterit: '群组名称已存在，请重新输入',
  Groupcreatedsuccessfully: '群组创建成功',
  Groupcreationfailedpleasecreateagain: '群组创建失败，请重新创建！',
  Pleaseenterthegrouppassword: '请输入群口令',
  Thegrouppasswordyouenterediswrong: '您输入的群口令错误',
  Grouppassword: '群口令',
  Dissolve: '解散',
  Exit: '退出',
  Disbandselforganizedgroup: '解散自建组',
  Exittheselfcreatedgroup: '退出自建组',
  ExiDissolvetheselfestablishedgroupt: '确定要解散自建组吗？',
  Exitselfbuiltgroup: '确定要退出该自建组吗？',
  Grouptransfer: '群主转让',
  Theusersselfbuiltgroupfunctionisnotopened: "用户自建组功能未开启",
  Createfence: '创建围栏',
  Editfence: '编辑围栏',
  electricfence: '电子围栏',
  Electronicfencelist: '电子围栏列表',
  fencename: '围栏名称',
  efficient: '有效',
  Invalid: '失效',
  Forbidden: '禁出',
  Noentry: '禁入',
  Pleasesearchorselfselectthefencedareafirst: '请先圈选围栏区域！',
  Areyousureyouwanttodeletetheelectronicfence: '确定要删除电子围栏吗',
  Triggermode: '触发方式',
  timesetting: '时间设定',
  Pleaseenterthefencename: '请输入围栏名称',
  tipsItconsists: '由1-15位中文,数字,字母，下划线组成，且不能以下划线开头',
  tipsItconsistsName: '围栏名称由1-15位中文,数字,字母，下划线组成，且不能以下划线开头',
  Pleaseselectamethodofentryandexit: '请选择出入方式!',
  Pleaseselectatleastoneday: '请至少选择一天',
  Notinfenceusers: '不在围栏用户',
  Usersonthefence: '在围栏用户',
  Validdate: '有效日期',
  Validperiod: '有效时段',
  Selecttimerange: '选择时间范围',
  Monday: '周一',
  Tuesday: '周二',
  Wednesday: '周三',
  Thursday: '周四',
  Friday: '周五',
  Saturday: '周六',
  Sunday: '周日',
  Createdsuccessfully: '创建成功',
  Thefencenameisduplicated: '围栏名称重复，请修改后重试',
  Ifnotselected: '不选择默认永久有效',
  FenceEdit: '围栏编辑',
  Removalfailedfence: '移除失败，在围栏用户列表为空！',
  Failedtoaddfence: '添加失败，不在围栏用户列表为空！',
  Outofboundsrecord: '越界记录',
  Outofboundstype: '越界类型',
  Pleaseselectausername: '请选择用户名称',
  Pleaseselecttriggermethod: '请选择触发方式',
  Crossborderdate: '越界日期',
  currentrecord: '当前记录',
  Triggertime: '触发时间',
  Pleaseselectafencename: '请选择围栏名称',
  Releasetime: '解除时间',
  Batchdownload: '批量下載',
  Recordingplayback: '录音播放',
  download: '下载',
  Areyousureyouwant: '确定下载所选的录音吗?',
  Record: '记录',
  Pleaseselecttherecording: '请选择要下载的录音记录',
  downloadsuccessful: '下载成功',
  Voiceaudio: '语音音频',
  text: '文本',
  voice: '语音',
  Doubleclicktocreate: '双击创建',
  Doubleclicktosave: '双击保存',
  outofboundsperson: '越界人',
  temporarygroupTow: '临时群组',
  Theotherpartyhasnoanswer: '对方无应答！',
  sosrecord: 'SOS历史记录',
  UnhandledSOSexists: '存在未处理的SOS'
};